














































































































































































































































































































import BigNumber from "bignumber.js";
import { Component, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import {mapGetters, mapState} from "vuex";

import { GasPrice, GasPriceInfo, IndexedCurrency } from "@/types";
import { checkOrApprove, timeout, getImgUrl } from "@/helpers";
import state, { isLoadedState } from "@/contract";
import { selectedNetwork } from "@/network";
import { ETH_MAINNET } from "@/address";
import { initToken } from "@/init";
import filtersMixin from "@/mixins/filters";
import { PLACEHOLDER_CURRENCIES_INFO } from "@/coins";
import Popup, { TRANSACTION_STATUS } from "@/components/UI/Popup.vue";

@Component({
    name: "Auction",
    components: { vSelect },
    mixins: [filtersMixin],
    data: () => ({
        coins: state.coins
    }),
    computed: {
        ...mapGetters({
            getGasPriceOptions: 'getGasPriceOptions',
            sigTokenBalance: 'getSigTokenBalance',
        }),
        ...mapState([
            'balanceChartData',
            'total',
            'fee',
            'adminFee',
        ])
    },
    methods: {
        getImgUrl,
    }
})
export default class Auction extends Vue {
    public sigTokenBalance!: string;

    private IS_DISABLED = selectedNetwork.chainId === ETH_MAINNET; // Disable only for ETH Mainnet

    private currencies = PLACEHOLDER_CURRENCIES_INFO
    private selectedBuyToken = this.currencies[0]
    private isAdvancedOptionsOpened = false
    private notAllowToBurn = false

    private currentPrice: BigNumber | null = null
    private burnAmount = ''
    private buyTokenAmount = ''

    // private balances: BigNumber[] | null = null
    private balances: any[] = []
    private adminBalances: any[] = []

    getGasPriceOptions!: GasPriceInfo[]
    private gasOptionsSelect = { label: '', value: new BigNumber(1e9) }

    // TODO: this doesnt work
    async mounted () {

        while (!state.web3 || !state.isReady) {
            // DON'T RENDER AT ALL
            console.log("waiting 1 sec")
            await timeout(1000);
        }

        // we need to take last so transactions are mined fast
        this.gasOptionsSelect = this.getGasPriceOptions.slice(-1)[0];

        this.fetchBuyTokenBalances()
        // this.updateCurrentPrice()
        this.updateBurnAmount()
    }

    get gasPrice (): GasPrice {
        return new BigNumber(this.gasOptionsSelect.value)
    }

    async auctionCoins(index: number): Promise<IndexedCurrency> {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        const coinAddress = await state.dutchAuction.methods.coins(index).call()

        return await initToken(state.web3, coinAddress, index) as IndexedCurrency;
    }

    async fetchBuyTokenBalances() {
        const coins = await Promise.all(this.currencies.map((_, index) => this.auctionCoins(index)))

        try {
            const balances = await Promise.all(
              coins.map(async coin => {
                  const amount = await coin.contract.methods.balanceOf(state.dutchAuctionAddress).call()

                  return {
                      amount: new BigNumber(amount).div(coin.precision),
                      symbol: coin.symbol
                  }

                  // return new BigNumber(amount).div(coin.precision)
              })
            )
            this.balances = balances
        } catch (e) {
            console.log(e)
        }

        try {
            const adminBalances = await Promise.all(
              coins.map(async (coin, i) => {
                  const amount = await state.swapContract?.methods.admin_balances(i).call()

                  return {
                      amount: new BigNumber(amount).div(coin.precision),
                      symbol: coin.symbol
                  }
              })
            )
            this.adminBalances = adminBalances
        } catch (e) {
            console.log(e)
        }
    }

    withdrawAdminFees () {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }

        const result = state.proxyContract.methods
          .withdraw_admin_fees_to_auction()
          .send({
            from: state.defaultAccount
        });

        console.log('result', result);
    }

    openAdvOptions() {
        this.isAdvancedOptionsOpened = !this.isAdvancedOptionsOpened;
    }

    checkAmount () {
        const coin = this.balances[this.selectedBuyToken.index];
        // because numbers are stored as strings here
        this.notAllowToBurn = new BigNumber(this.buyTokenAmount).gt(coin.amount);
    }

    async updateSetMaxSigBurnAmount () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        this.burnAmount = this.sigTokenBalance.toString()

        this.updateBurnAmount()
    }

    async updateSetMaxBurnAmount () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        const coin = this.balances[this.selectedBuyToken.index];

        if (!coin) { return }

        this.burnAmount = coin.amount.div(this.currentPrice).toFixed(18)

        this.updateBurnAmount()
    }

    async updateBurnAmount() {
        // TODO: add loader this.isLoading = true; this.isLoading = false;

        await this.updateCurrentPrice()

        this.buyTokenAmount = this.currentPrice?.times(this.burnAmount || 0)?.toString() || ''
    }

    async updateCurrentPrice () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        const { selectedBuyToken } = this;

        const currentBlock = await state.web3.eth.getBlockNumber();

        const price = await state.dutchAuction.methods.getSig1e18Price(currentBlock).call();

        this.currentPrice = new BigNumber(price).div(selectedBuyToken.precision);

        this.fetchBuyTokenBalances()
    }

    async submitBurn () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }
        if (this.notAllowToBurn) return

        const amount = new BigNumber(this.burnAmount).times(state.sigToken.precision);

        console.log('amount', amount.toFixed(0));

        this.burn(this.selectedBuyToken.index, amount);
    }

    async submitApprove () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        // doesn't matter, because still infinite approval
        const amount = new BigNumber(this.burnAmount).times(state.sigToken.precision);

        const gasPrice = this.gasPrice.times(1e9)

        try {
            await checkOrApprove(state.sigToken.address, state.dutchAuctionAddress, amount, true, gasPrice);
        } catch (e) {
            this.$notify(Popup.plain.error(TRANSACTION_STATUS.approveFailed, e.message));
            return
        }

    }

    async burn(buyTokenIndex: number, burnAmount: BigNumber) {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        await this.updateCurrentPrice();

        if (!this.currentPrice) { throw new Error("Cant fetch current price") }

        const minOutputAmount = this.currentPrice.times(burnAmount).times(1 - 1/100); // TODO: apply slippage
        const burnedAmountFormatted = burnAmount.div(state.sigToken.precision).toFormat(2)

        const gasPrice = this.gasPrice.times(1e9)

        // TODO: run estimateGas
        const gasLimit = 300_000;
            // await state.dutchAuction.methods
            // .sellSigForStablecoin(amount, coinIndex, minOutputAmount)
            // .estimateGas({
            //     from: state.defaultAccount,
            // });

        const isApproved = await checkOrApprove(state.sigToken.address, state.dutchAuctionAddress, burnAmount, true, gasPrice);

        if (!isApproved) {
            // TODO: check approval here, too
            return
        }

        try {
            console.log('sellSig', burnAmount.toFixed(0), buyTokenIndex, minOutputAmount.toFixed(0))
            console.log('gas', gasPrice, gasLimit)

            const result = state.dutchAuction.methods
                .sellSigForStablecoin(burnAmount.toFixed(0), buyTokenIndex, minOutputAmount.toFixed(0))
                .send({
                    from: state.defaultAccount,
                    gasPrice,
                    gasLimit,
                });


            result.on('transactionHash', (hash: string) => {
                console.log('tx hash', hash)
                // this.showAlert = false;
                this.$notify(Popup.tx.success(TRANSACTION_STATUS.created, hash))
            })

            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)

                this.$notify(Popup.tx.success(TRANSACTION_STATUS.burnedSig.replace('$', burnedAmountFormatted), receipt.transactionHash))
            })

            this.fetchBuyTokenBalances();
            this.updateCurrentPrice();

        } catch (err) {
            console.error(err);

            this.$notify({
                // TODO: error
                group: 'notify',
                title: 'Transaction failed!',
                text: `${err.message}`
            });

            throw err
        }

    }
}
